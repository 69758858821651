@font-face {
  font-family: 'Quicksand';
  font-weight: 300;
  src: url(./static/fonts/Quicksand/Quicksand-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  font-weight: 400;
  src: url(./static/fonts/Quicksand/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  font-weight: 500;
  src: url(./static/fonts/Quicksand/Quicksand-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  font-weight: 600;
  src: url(./static/fonts/Quicksand/Quicksand-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  font-weight: 700;
  src: url(./static/fonts/Quicksand/Quicksand-Bold.ttf) format('truetype');
}

/* Link */
a {
  text-decoration: none;
}
